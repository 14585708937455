<template src="./details.html"></template>

<script>
import Http from '@/shared/http-config'
import StatusBox from '../components/StatusBox'
import CustomerSearch from '../components/CustomerSearch'
import DeleteCustomer from '../components/DeleteCustomer'
import moment from 'moment'
import store from '../../../Store';
import CustomerStore from '../CustomerStore'
import RegisterStoreModule from '@/shared/registerStoreModule'
import { mapFields } from 'vuex-map-fields';

export default {
  name: "Details",
  components: { StatusBox, CustomerSearch, DeleteCustomer },
  mixins: [RegisterStoreModule],
  data: function () {
    return {
      customer: {
        id: 0,
        address: {},
        payment: {
          method: {},
          dates: {}
        },
        branch: {},
        teacher: {},
      },
      branches: [],
      teachers: [],
      payment_installments: false,
      classes: [],
      packets: [],
      new_barcode: '',
      barcodes: [],
      new_education: {
        class: null,
        package_variation: null,
        package: null,
        start: new Date(),
        passed_talk: false,
      },
    }
  },
  computed: {
    package_variations () {
      return this.$store.getters['customers/get']('package_variations');
    },
    ...mapFields('customers', [
      'education',
      'educations',
      'all_educations',
      'packages',
    ]),
    oldSystemUrl () {
      return process.env.VUE_APP_OLD_URL;
    },
  },
  watch: {
    $route (to, from) {
      if(from.params.education !== to.params.education && from.params.id === to.params.id){
        this.education = this.all_educations.filter(item => item.id === parseInt(to.params.education))[0];
        this.education.picture_visibility_model = [];
        if(this.education.picture_visibility & 1){
          this.education.picture_visibility_model.push(1);
        }
        if(this.education.picture_visibility & 2){
          this.education.picture_visibility_model.push(2);
        }
      }
      else if(from.params.id !== to.params.id) {
        this.getEducations();
        this.getCustomer();
      }
    }
  },
  mounted: function(){
    this.$store.commit('set', ['containerFluid', true]);
  },
  destroyed: function(){
    this.$store.commit('set', ['containerFluid', false]);
  },
  beforeRouteEnter: function (to, from, next) {
    Promise.all([
      store.dispatch('fetchBranches'),
      store.dispatch('fetchTeachers'),
      store.dispatch('fetchClasses')
    ]).finally(() => {
      next((vm) => {})
    })
  },
  created: function(){
    let self = this;
    self.registerStoreModule('customers', CustomerStore);
    self.$store.dispatch('customers/fetchStates').then(function (){
      self.getEducations();
      self.branches = self.$store.getters.get('branches');
      self.teachers = self.$store.getters.get('teachers');
      self.$store.getters.get('classes').forEach(item => {
        self.classes.push({value: item, text: item.name});
      });
    });

    Http.request('GET', '/customers/packets').then((packets) => {
      packets.data.forEach(item => {
        self.packets.push({value: item, text: item.name});
      });
    });

    if(parseInt(self.$route.params.id) > 0){
      this.getCustomer();
    }
  },
  methods: {
    getCustomer(){
      let self = this;
      Http.request('GET', '/customers/' + self.$route.params.id).then((customer) => {
        self.customer = customer.data;
        if(self.customer.birthday === '0000-00-00'){
          delete self.customer.birthday;
        }
        if(self.customer.branch === null){
          self.customer.branch = {};
        }
        if(self.customer.teacher === null){
          self.customer.teacher = {};
        }
        if(self.customer.payment.method === null){
          self.customer.payment.method = {};
        }
        if(self.customer.payment.dates.length > 0){
          self.payment_installments = true;
        }
        self.$store.commit('set', ['breadcrumbTxt', self.customer.name]);
      });
    },
    getEducations(select_education = false){
      let self = this;
      if(parseInt(self.$route.params.id) > 0){
        self.$store.dispatch('customers/fetchEducations', self.$route.params.id).then(function(){
          if(self.all_educations.length === 1 && parseInt(self.all_educations[0].id) !== parseInt(self.$route.params.education)){
            self.education = self.all_educations[0];
            self.$router.push('/customers/dashboard/' + self.$route.params.type + '/details/' + self.$route.params.id + '/' + self.all_educations[0].id + '/' + self.$route.name);
          }
          else if(select_education.id > 0){
            self.$router.push('/customers/dashboard/' + self.$route.params.type + '/details/' + self.$route.params.id + '/' + select_education.id + '/' + self.$route.name);
          }
          else if(parseInt(self.$route.params.education) > 0){
            self.education = self.all_educations.filter(item => item.id === parseInt(self.$route.params.education))[0];
          }
          else if(self.education.id === 0 && self.all_educations.length > 0){
            self.$router.push('/customers/dashboard/' + self.$route.params.type + '/details/' + self.$route.params.id + '/' + self.all_educations[0].id + '/' + self.$route.name);
          }
        });
      }
    },
    getCustomerBarcodes(){
      let self = this;
      Http.request('GET', '/customers/' + self.$route.params.id + '/barcodes').then((barcodes) => {
        self.barcodes = barcodes.data;
      });
    },
    saveCustomerBarcode(){
      let self = this;
      Http.request('POST', '/customers/' + self.$route.params.id + '/barcodes', {barcode: self.new_barcode}).then((barcodes) => {
        self.barcodes.push(barcodes.data);
        self.new_barcode = '';
      });
    },
    saveEducation(){
      let self = this;
      let formData = {};
      if(self.new_education.class !== null){
        formData.class = {};
        formData.class.id = self.new_education.class.id;
      }
      else if (self.new_education.package && self.new_education.package.id > 0) {
        if(self.new_education.package_variation === null){
          self.$bvToast.toast('Bitte wählen sie ein ABC Modell aus', {
            title: 'Fehler',
            variant: 'warning',
            autoHideDelay: 5000
          });
          return;
        }
        formData.package = {};
        formData.package.id = self.new_education.package.id;
        formData.package_variation = self.new_education.package_variation;

        formData.class = {};
        formData.class.id = self.new_education.package.classes[0].id;
      }
      else {
        self.$bvToast.toast('Bitte wähle einen Standard-Vertrag oder Vertrag mit ABC-Modell aus.', {
          title: 'Fehler',
          variant: 'warning',
          autoHideDelay: 5000
        });
        return;
      }

      formData.start = moment(self.new_education.start).format('YYYY-MM-DD');
      formData.passed_talk = self.new_education.passed_talk;

      formData.state = self.$store.getters['customers/get']('states')[0];

      if(!self.payment_installments){
        delete self.customer.payment.dates;
      }
      Http.request('POST', '/customers/' + self.$route.params.id, self.customer).then(function (response) {
        Http.request('POST', '/customers/' + self.$route.params.id + '/educations', formData).then((response) => {
          self.getEducations(response.data);
          self.$bvModal.hide('add_education');
          self.$bvToast.toast('Ausbildung gespeichert', {
            title: 'Gespeichert',
            variant: 'success',
            autoHideDelay: 5000
          });
          Object.assign(self.$data.new_education, self.$options.data().new_education);

          let filename = 'vertrag_' + self.customer.first_name + '_' + self.customer.last_name + '_' + moment().format('DDMMYYYY') + '.pdf';
          Http.requestFile('/customers/' + self.$route.params.id + '/educations/' + response.data.id + '/contract', filename, true);
        });
      });
    },
    customerDeleted(){
      this.$router.push('/customers/dashboard/' + this.$route.params.type)
    },
    deleteEducation(education){
      let self = this;

      let text = '';
      if(education.package && education.package.name){
        text = 'das Programm (' + education.package.name + ' ' + self.package_variations[education.package_variation] + ')';
      }
      else if (education.class){
        text = 'die Klasse (' + education.class.name + ')';
      }

      self.$bvModal.msgBoxConfirm('Möchtest Du ' + text + ' wirklich löschen?', {
        headerBgVariant: 'primary',
        title: 'Klasse löschen?',
        okTitle: 'Ja',
        cancelTitle: 'Nein',
        cancelVariant: 'outline-danger'
      })
      .then(value => {
        if(value === true){
          Http.request('DELETE', '/customers/' + self.$route.params.id + '/educations/' + education.id).then(function (response) {
            self.$store.dispatch('customers/fetchEducations', self.$route.params.id).then(function(){
              if(self.all_educations.length > 0){
                self.education = self.all_educations.filter(item => item.id === self.all_educations[0].id)[0];
                self.$router.push('/customers/dashboard/' + self.$route.params.type + '/details/' + self.$route.params.id + '/' + self.all_educations[0].id + '/' + self.$route.name);
              }
            });
          });
        }
      });
    },
  }
}
</script>

<style scoped></style>
